.App {
  text-align: center;
}
/* .down-arrow {
  position: absolute;
  top: calc(100vh - 80px);
  left: calc(50% - 14px);
  width: 4em;
  height: 4em;
  border-radius: 400px;
  background-color: blue;

  animation: jumpInfinite 2s infinite;
}

.down-arrow:after {

  position: absolute;
  

  transform: rotateZ(45deg);
}

@keyframes jumpInfinite {
  0% {
    margin-top: 0;
  }
  50% {
    margin-top: 20px;
  }
  100% {
    margin-top: 0;
  }
}
 */


.gappe{ background-blend-mode:"difference"; background: linear-gradient(0deg, rgba(209,209,209,0) 0%, rgb(202, 202, 202) 51%, rgba(0,212,255,0) 100%);}
.actives{background-color: rgb(58, 152, 214);}
.active{color: rgb(255, 255, 255)!important;
  font-size: larger;
  font-weight: 800;
  transition: 0.2s;
  text-shadow: /* -1px -1px 0 rgb(241, 0, 0), 1px -1px 0 rgb(0, 255, 13), */ 2px 2px 0 rgb(99, 99, 99)/* , 1px 1px 0 rgb(255, 0, 255); */
  
  
}
button[role=tab] {
  font-weight: 800;
  color:rgb(44, 44, 44);
  
 
 

}
#tabs-:rb:--tab-0
/* button[aria-selected="false"]{
  color: rgb(0, 0, 0) !important;
 
  

} */
/* .tabmenu{ } */

div[role="tablist"]{
  background-color: rgba(0, 43, 184, 0.219);
  border-radius: 20px;
  
 
}
button[aria-selected="true"] {
  /* background-color: rgb(0, 17, 255); */
  border: white 20px;
  border-radius: 20px;
/*   color: rgb(255, 255, 255) !important; */
 /*  font-size: x-large; */
}
button[role="tab"]:last-of-type + button{
  background-color: rgb(238, 0, 0)!important;
  border: white 20px;
  border-radius: 20px;
  
  color: rgb(248, 0, 0) !important;
 /*  font-size: x-large; */
}
.navinact { color: rgb(116, 116, 116);}
p>a>a.active { 
  background: rgba(0, 0, 0, 0.2);
  border-bottom: none;
/*   border-width: 2px;
  border-radius: 4px; */
  border-color: rgba(53, 53, 53, 0.1);

}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.main {
  width: 100%;
  height: 100vh;
}

video {
  width: 100%;
 /*  height: 200%; */
  object-fit: cover;
  position: relative;
}
title {color:rgb(58, 152, 214)}
.content {
  /* position: absolute; */
  width: 100%;
  height: 100%;
  top: 0;
  /* display: flex;
  flex-direction: column; */
  /* justify-content: center; */
/*   align-items: center; */
  /* color: white; */
}
.fishar{
  filter: grayscale(0.6) ;
}
.fishbulls{
filter: contrast(90%) brightness(80%) grayscale(0.8) blur(4px) ;
backdrop-filter: auto;

}
.overlay {
  position: absolute;
  z-index: -3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(78, 78, 78, 0.4); */
  /* background-color: #000000cc; */
}
