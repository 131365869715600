body {
  margin: 0;
  
  font-family:  "Nunito Sans";
  
  /* font-family: -apple-system, BlinkMacSystemFont, "Nunito Sans" 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
title {color:rgb(58, 152, 214);
display: block;
}
/* video{
  height: 200vh;
} */

.imagero{
  -webkit-mask-image: -webkit-gradient(linear, left bottom, left top,  from(rgba(0,0,0,1)),to(rgba(0,0,0,0) ))
}
/* 'linear-gradient( rgba(43,108,176,0) 0%,  rgba(43,108,176,0.3) 10%,rgba(43,108,176,0.8) 30%,rgba(43,108,176,1) 50%, rgba(66,153,225,1) 90%)'} */
iframe {
  margin-top: -60px;
  margin-bottom: -60px;
}
.active{color: rgb(255, 23, 43);}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#boxers {min-width: 10px;}

/* .signature {
  stroke-dasharray: 2000;
  animation: writebase 5s infinite linear;
  
}
@keyframes writebase {
  0%{
    stroke-dashoffset: 4600;
  }
  80%{
    stroke-dashoffset: 0;
  }
} */
#signaturos,
line {
  /* width: 20%; */
   fill: none; 
  stroke: #000dc2;
  stroke-width: 30;
  stroke-linecap: round;
  stroke-linejoin: round; 
   stroke-miterlimit: 10; 
}
/* .signature{
  width: 10em;
} */

/* give the vectors a dashed stroke */
/* .stroke-I {
  stroke-dasharray: 2000;
  animation: write1 2s 1 ease-out;
} */

.stroke-an {
  
  stroke-dasharray: 20000;
  animation: write1  6s    linear ;
}
/* 
.stroke-flourish {
  stroke-dasharray: 2000;
  animation: write3 2s 1 ease-in;
} */

/*  keyframes */
@keyframes write1 {
    0% {
        stroke-dashoffset: 20000;
    }
 
    80% {
      stroke-dashoffset: 0;
    }
}

/* @keyframes write2 {
    0%, 25%  {
        stroke-dashoffset: 360;
    }
    90% {
        stroke-dashoffset: 0;
    }
}

@keyframes write3 {
    0%, 90% {
        stroke-dashoffset: 40;
    }
    100% {
        stroke-dashoffset: 0;
    }
} */

.label-text {
  color: white;
  font-weight: 500;
}
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.full-screen-container {
   position: relative; 
  top: 0;
  left: 0;
  width: 100%;
  height: 200vh;
  overflow: hidden;
  margin-bottom: -50px;
  /* height: 200%;
  width: 200%; */
}

.full-screen-video {
  top: 0;
  left: 0;
  position: sticky; 
  width: 100%;
  height: 100%;
  z-index: 99;
  object-fit: cover;
  overflow: hidden;
  margin-bottom: -50px;
  /* z-index: -1; */
}

.react-vimeo-player {
  overflow: hidden;
  top: 0;
  left: 0;
  /* position: absolute; */
  width: 300%;
  height: 200%;
  z-index: 99;
  object-fit: cover;
  z-index: -1;
  margin-right: -50px;
  margin-bottom: -120px;
}


@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translate3d(0, -20%, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}